import * as React from "react"
import { Link, withPrefix } from "gatsby"

const Page404 = (props) => {

    const imgPath = withPrefix(`static/images/site/404_image.png`);

    return (
        <main className="page-404">
            <img src={imgPath} alt="Page not found 404 error"/>
            <div className="e-title">Page Not Found</div>
            <div className="e-body">The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</div>
            <Link className="e-btn" to="/">Go home</Link>.
        </main>
    );
}

export default Page404;
